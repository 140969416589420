.materials {
    background: #ccc;
    padding: 15px;
    border-radius: 4px;

    > div {
        display: grid;
        grid-template-columns: 40px 1fr;
        gap: 20px 15px;
        align-items: center;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
