.Input {
    margin-bottom: 15px;
    font-weight: 500;
}

.Input.form-field {
    margin-bottom: 16px;
}

.Input label {
    margin-bottom: 0;
    padding: 0;
    display: block;
    font-size: 14px;
}

.Input input {
    border: 1px solid var(--black-color);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.2em;
    padding: 12px 16px;
    width: 100%;
    outline: none;
    transition: all 300ms ease-in-out;
    color: var(--black-color);
    max-width: unset;
}

.Input input:focus {
    border: 1px solid var(--primary-color);
}

.Input input:hover {
    border: 1px solid var(--primary-hover);
}

.Input input:active {
    border: 1px solid var(--primary-color);
}


.Input span {
    color: var(--red-color);
    font-size: 12px;
    font-weight: bold;
}

.Input.invalid label {
    color: var(--red-color);
}

.Input.invalid input {
    border: 1px solid var(--red-color);
}