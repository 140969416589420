.Alert {
  color: #0c5460;
  background-color: #d1ecf1;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #bee5eb;
  border-radius: .25rem;
}

.order {
  background: var(--light-gray-color);
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;

  h2 {
    text-align: center;
    font-size: 28px;
  }

  .orderDate {
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
  }

  ol {
    padding-left: 20px;
  }
}

.threeColumns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.viewRow {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;

  .dashed {
    border-bottom: dashed 1px var(--gray-color);
  }
}

.editRow {
  display: grid;
  grid-template-columns: auto 100px 1fr;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr auto auto;
}

.error {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: var(--red-color);
}