.grid {
    display: grid;
    margin-bottom: 2px;
}

.title {
    font-size: 12px;
    color: var(--gray-color);
    line-height: 1.6em;
    font-weight: normal;
    padding-bottom: 8px;
    margin-top: 20px;

    > div {
        &:first-child {
            padding-left: 10px;
        }
    }
}

.column {
    text-align: center;
}

.row {
    background: #eee;
    > div {
        padding: 10px;
        &:nth-child(even) {
            background: #ffffff52;
        }
    }
}

.subtext {
    font-size: 12px;
}

.status {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    display: inline-block;
    margin-top: 14px;
}

.low {
   background: var(--item-expired);
}

.medium {
    background: var(--item-in-progress);
}

.high {
    background: var(--item-completed);
}
