body {
  --primary-color: #2196f3;
  --primary-hover: #007bff;
  --disabled-color: #7eafda;
  --gray-color: #B2B8CB;
  --dark-gray-color: #7986AF;
  --light-gray-color: #dfe3e5;
  --white-color: #ffffff;
  --blue-color: #F5F9FF;
  --light-blue-color: #A3B9F5;
  --black-color: #536493;
  --red-color: #F8571B;

  --expired-color: #f4433657;
  --completed-color: #4caf507d;
  --in-progress-color: #e6db6082;
  --canceled-color: #7955488f;

  --item-waiting: #9e9e9e;
  --item-in-progress: #ffc107;
  --item-completed: #4caf50;
  --item-expired : #f44336;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.copy {
  font-size: 12px;
  text-align: center;
  margin-bottom: 20px;
}