.header {
    display: grid;
    grid-template-columns: auto 1fr 120px;
    align-items: baseline;
}

.list {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;

    .listItem {
        display: grid;
        grid-template-columns: 100px 100px 1fr 100px;
        gap: 20px;
        margin-bottom: 1px;
        padding: 10px;
    }

    .gridItem {
        display: grid;
        grid-template-columns: 100px 100px 1fr 100px;
        margin-bottom: 1px;

        > div {
            padding: 10px 0;
            overflow: hidden;
            text-align: center;

            &:nth-child(even) {
                background: #ffffff52;
            }

            &:nth-child(2) {
                background: none;
            }

        }
    }

    .noRecords {
        grid-template-columns: 1fr;
    }

    .comments {
        width: 100%;
    }
}

.sellerList {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;

    li {
        display: grid;
        grid-template-columns: 100px 100px 1fr 100px;
        gap: 20px;
        margin-bottom: 1px;
        padding: 10px;
    }

    .noRecords {
        grid-template-columns: 1fr;
    }
}

.title {
    font-size: 12px;
    color: var(--gray-color);
    line-height: 1.6em;
    font-weight: normal;
    padding-bottom: 8px;
}

.item {
    background: #9e9e9e38;
    cursor: pointer;

    &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.expired {
    background: var(--expired-color);
}

.completed {
    background: var(--completed-color);
}


.inProgress {
    background: var(--in-progress-color);
}

.canceled {
    background: var(--canceled-color);
}

.dot {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    display: inline-block;
}

.statusWaiting {
    background: var(--item-waiting);
}

.statusInProgress {
    background: var(--item-in-progress);
}

.statusCompleted {
    background: var(--item-completed);
}

.statusExpired {
    background: var(--item-expired);
}

.newItem {
    height: 100%;
}

.link {
    cursor: pointer;
}

.sticky {
    position: sticky;
    top: 0;
    background: var(--white-color);
    z-index: 5;
}


@media print {
    .note {
        border: solid 2px #000;
        height: 150px;
        margin-bottom: 30px;
    }

    .report {
        margin: 30px;
    }
}
