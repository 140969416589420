.SwitchTabs {
  text-align: center;
  margin-bottom: 20px;

  button {
    border: solid 1px var(--gray-color);
    min-width: 80px;

    &:first-child {
      border-right: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .selected {
    background: var(--primary-color);
    color: var(--white-color);
  }
}