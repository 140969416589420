.header {
    display: grid;
    grid-template-columns: 1fr auto auto auto;
}

.row {
    gap: 0 !important;
    padding: 0 !important;
    > div {
        padding: 10px 20px;
    }
}

.column {
    background: #ffffff52;
}
