.Button {
    margin-top: 16px;
    background: var(--white-color);
    border: solid 1px var(--primary-color);
    color: var(--primary-color);
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.6em;
    padding: 12px 16px;
    margin-right: 16px;
    display: inline-block;

    span {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 8px;
    }
}

.Button:hover {
    background: var(--light-gray-transparent-color);
    color: var(--primary-hover);
    border: solid 1px var(--primary-hover);
}

.Button:focus {
    outline: none;
}

.Button:active {
    border: solid 1px var(--light-blue-color);
}

.Button:disabled {
    background: #ccc;
    color: var(--gray-color);
    cursor: not-allowed;
}

.error {
    background: rgba(240, 87, 108, 1);
}

.success {
    background: rgba(161, 240, 69, 1);
}

.primary {
    background: var(--primary-color);
    color: var(--white-color);

    span {
        i:before {
            color: var(--white-color);
        }
    }
}

.primary:hover {
    background: var(--primary-hover);
    text-decoration: none;
    color: var(--white-color);
}

.second {
    background: var(light-gray-color);
    color: var(--primary-color);
}

.second:hover {
    background: var(--gray-color);
    color: var(--primary-hover);
    border: solid 1px var(--primary-hover);
}

.third {
    border: none;
    background: none;
    color: var(--dark-gray-color);
    padding: 0;
    display: inline-block;
}

.third:hover {
    border: none;
    color: var(--black-color);
    background: none;
}

.third:active {
    background: none;
    color: var(--dark-gray-color);
    border: none;
}

.third:disabled {
    background: none;
    color: var(--gray-color);
    cursor: not-allowed;
}