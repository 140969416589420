.Checkbox label {
    font-size: 14px;
    line-height: 1.6em;
}

.Checkbox.invalid label {
    color:var(--red-color);
}

.Checkbox.invalid label a {
    color: var(--red-color);
}