.tile {
    min-height: 100px;
    border: solid 1px var(--gray-color)
}

.tile:disabled {
    background: var(--expired-color);
}

.tile:not(:disabled) {
    background: var(--white-color);
}

:global(.react-calendar .react-calendar__tile:hover) {
    border-color: yellow;
}

:global(.react-calendar .react-calendar__tile--active) {
    background: var(--completed-color);
}

:global(.react-calendar__navigation__prev2-button) {
    display: none;
}

:global(.react-calendar__navigation__next2-button) {
    display: none;
}

:global(.react-calendar__navigation__arrow) {
    border: none;
    background: var(--primary-color);
    padding: 3px 13px;
    border-radius: 4px;
    color: #fff;
}

:global(.react-calendar__navigation__arrow:disabled) {
    background: var(--disabled-color);
}

:global(.react-calendar__navigation__label) {
    background: none;
    border: none;
}

:global(.react-calendar__navigation) {
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    margin-bottom: 20px;
}

:global(.react-calendar) {
    margin: 30px 0;
}
