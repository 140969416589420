.select {
    margin-bottom: 15px;
}

.select label {
    margin-bottom: 0;
    padding: 0;
    display: block;
    font-size: 14px;
    font-weight: 500;
}

:export {
    blackColor: var(--black-color);
    blueColor: var(--blue-color);
    primaryColor: var(--primary-color);
    primaryHoverColor: var(--primary-hover);
    lightBlueColor: var(--primary-color);
    whiteColor: var(--white-color);
    fontSize: 14px;
    lineHeight: 1.6em;
    borderColor: var(--black-color);
    padding: 2.5px 0 2px 6px;
    errorColor: var(--red-color);
}

.select span {
    color: var(--red-color);
    font-size: 12px;
    font-weight: bold;
}

.small {
    display: inline-block;
    margin: 0 0 0 24px;

    label {
        font-weight: normal;
        display: inline-block;
        margin-right: 8px;
        font-size: 12px;
        color: var(--gray-color);
    }

    select {
        border: none;
        font-size: 12px;
        font-weight: bold;
        color: var(--black-color);
    }
}