.Tooltip {
    text-align: center;

    :global(.form-field) {
        margin-bottom: 5px;

        label {
            font-size: 12px;
        }
    }
}


:global(.react-tooltip-lite) {
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    border-radius: 4px;
    width: 300px !important;

    textarea {
        min-height: 150px;
    }

}

.buttons {
    display: grid;
    grid-template-columns: auto 1fr 1fr;

    button {
        margin: 0;
        padding: 5px 10px;
    }
}

.body {
    text-align: left;
}

.name {
    background: var(--light-gray-color);
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 4px;
}